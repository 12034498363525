import { Device, from } from '@helpers/media';
import styled from 'styled-components';
const DesktopOnly = styled.div `
  display: none;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;
const MobileOnly = styled.div `
  display: block;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;
export default {
    DesktopOnly,
    MobileOnly,
};
