import { HydrateOption } from '@core/enums';
import withWidget from '@hoc/withWidget';
import { ReactComponent as Email } from '@img/icons/email.svg';
import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import DesktopOnly from '@stories/Components/Containers/Responsive/DesktopOnly';
import MobileOnly from '@stories/Components/Containers/Responsive/MobileOnly';
import SiteWide from '@stories/Components/Containers/SiteWide/SiteWide';
import EnquireNowModal from '@stories/Components/Misc/EnquireNowModal/EnquireNowModal';
import React from 'react';
import { EnquireNowStyles } from './EnquireNow.styles';
const EnquireNow = (props) => {
    return (React.createElement(EnquireNowStyles.Wrapper, null,
        React.createElement(MobileOnly, null,
            React.createElement(SiteWide, null,
                React.createElement(LinkButton, { branding: "secondary", title: props.buttonTextMobile, href: props.buttonUrl, addon: React.createElement(Email, null), addonPosition: 'left' }))),
        React.createElement(DesktopOnly, null,
            React.createElement(SiteWide, null,
                React.createElement(LinkButton, { branding: "secondary", title: props.buttonText, href: props.buttonUrl, addon: React.createElement(Email, null), addonPosition: 'left' }))),
        React.createElement(EnquireNowModal, { siteId: props.siteId, recaptchaSiteKey: props.recaptchaSiteKey, translations: props.modalTranslations, divisions: props.divisions })));
};
export default withWidget(EnquireNow, 'EnquireNow', { hydrate: HydrateOption.InView });
