export var Device;
(function (Device) {
    Device[Device["MobileSmall"] = 320] = "MobileSmall";
    Device[Device["MobileMedium"] = 360] = "MobileMedium";
    Device[Device["Mobile"] = 375] = "Mobile";
    Device[Device["MobileLarge"] = 480] = "MobileLarge";
    Device[Device["TabletSmall"] = 568] = "TabletSmall";
    Device[Device["Tablet"] = 768] = "Tablet";
    Device[Device["TabletLarge"] = 1024] = "TabletLarge";
    Device[Device["DesktopSmall"] = 1280] = "DesktopSmall";
    Device[Device["Desktop"] = 1366] = "Desktop";
    Device[Device["DesktopLarge"] = 1600] = "DesktopLarge";
    Device[Device["ActualDesktop"] = 1920] = "ActualDesktop";
    Device[Device["DesktopXL"] = 2560] = "DesktopXL";
})(Device || (Device = {}));
export function from(size) {
    return `(min-width: ${size}px)`;
}
export function until(size) {
    return `(max-width: ${size - 1}px)`;
}
export function between(min, max) {
    return `${from(min)} and ${until(max)}`;
}
