import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { ButtonReset } from '@helpers/global';
import { Device, until } from '@helpers/media';
import { ReactComponent as SvgHoverLines } from '@img/line-pattern.svg';
import styled, { css } from 'styled-components';
const Text = styled.div ``;
const HoverEffect = styled(SvgHoverLines) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0, 0);
  transition: transform 0.3s;

  [data-theme='textOnly'] & {
    display: none;
  }
  [data-theme='textOnlyPrimary'] & {
    display: none;
  }
  [data-theme='primary'] &,
  &[data-theme='primary'] {
    color: ${brand.primary.base};
  }
  [data-theme='primaryAlt'] &,
  &[data-theme='primaryAlt'] {
    color: ${brand.primary.alt};
  }
  [data-theme='secondary'] &,
  &[data-theme='secondary'] {
    color: ${brand.accent.orange};
  }
  [data-theme='white'] &,
  &[data-theme='white'] {
    color: ${brand.white};
  }
  [data-theme='black'] &,
  &[data-theme='black'] {
    color: ${brand.black};
  }
  [data-theme='grey'] &,
  &[data-theme='grey'] {
    color: ${brand.black};
  }
`;
const PrimaryStyles = css `
  color: ${brand.white};
  background-color: ${brand.primary.base};

  &:hover {
    background-color: #a2b952;
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.white};
  }
`;
const PrimaryAltStyles = css `
  color: ${brand.white};
  background-color: ${brand.primary.alt};

  &:hover {
    background-color: #a2b952;
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.white};
  }
`;
const SecondaryStyles = css `
  background-color: ${brand.accent.orange};
  color: ${brand.white};

  &:focus {
    background-color: ${brand.accent.orange};
    color: ${brand.white};
  }

  &:hover {
    border-color: ${brand.black};
    background-color: ${brand.black};
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.grey.grey55};
  }
`;
const WhiteStyles = css `
  background-color: ${brand.white};
  color: ${brand.black};
  border: 1px solid ${brand.white};

  &:hover {
    border-color: ${brand.white};
    background-color: ${brand.white};
    color: ${brand.black};
  }

  &:active,
  &:focus {
    color: ${brand.black};
  }
`;
const BlackStyles = css `
  background-color: ${brand.grey.grey20};
  color: ${brand.white};

  &:hover {
    border-color: ${brand.grey.grey44};
    background-color: ${brand.grey.grey44};
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.white};
  }
`;
const GreyStyles = css `
  background-color: ${brand.grey.grey89};
  color: ${brand.black};

  &:hover {
    border-color: ${brand.grey.grey76};
    background-color: ${brand.grey.grey76};
    color: ${brand.black};
  }

  &:active,
  &:focus {
    color: ${brand.black};
  }
`;
const TextOnlyStyles = css `
  border: 1px solid transparent;
  background: none;
  color: ${brand.black};

  &:hover {
    border-color: ${brand.black};
    background-color: ${brand.black};
    color: ${brand.white};
  }

  &:active,
  &:focus {
    color: ${brand.white};
  }
`;
const TextOnlyPrimaryStyles = css `
  ${TextOnlyStyles};
  color: ${brand.primary.alt};

  &:hover {
    border-color: transparent;
    background-color: transparent;
    color: ${brand.primary.base};
  }

  &:active,
  &:focus {
    color: ${brand.primary.base};
  }
`;
const IconLeft = css `
  padding-right: 12px;

  @media ${until(Device.DesktopSmall)} {
    padding-right: 6px;
  }
`;
const IconRight = css `
  padding-left: 15px;

  @media ${until(Device.DesktopSmall)} {
    padding-left: 6px;
  }
`;
const hideText = css `
  ${Text} {
    @media ${until(Device.DesktopSmall)} {
      display: none;
    }
  }
`;
const Icon = styled.div `
  display: inline-block;
  vertical-align: middle;

  ${({ position }) => {
    switch (position) {
        case 'left':
            return IconLeft;
        case 'right':
            return IconRight;
        default:
            break;
    }
}}

  svg {
    vertical-align: middle;
    max-height: 24px;
    width: 22px;
  }
`;
const Inner = styled.div `
  position: relative;
  padding: 11px 14px;

  [data-theme='textOnly'] & {
    ${TextOnlyStyles}
  }
  [data-theme='textOnlyPrimary'] & {
    ${TextOnlyPrimaryStyles}
  }
  [data-theme='primary'] & {
    ${PrimaryStyles}
  }
  [data-theme='primaryAlt'] & {
    ${PrimaryAltStyles}
  }
  [data-theme='secondary'] & {
    ${SecondaryStyles}
  }
  [data-theme='white'] & {
    ${WhiteStyles}
  }
  [data-theme='black'] & {
    ${BlackStyles}
  }
  [data-theme='grey'] & {
    ${GreyStyles}
  }
`;
const Button = styled.button `
  ${ButtonReset};
  display: inline-block;
  padding: 0;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s, opacity 100ms;
  user-select: none;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    ${HoverEffect} {
      transform: translate(10px, 10px);
    }
  }

  &:disabled {
    user-select: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${Text} {
    ${fonts.DaxPro.Bold};
    font-size: 18px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
  }

  ${({ branding }) => {
    switch (branding) {
        case 'textOnly':
            return TextOnlyStyles;
        case 'textOnlyPrimary':
            return TextOnlyPrimaryStyles;
        case 'primary':
            return PrimaryStyles;
        case 'secondary':
            return SecondaryStyles;
        case 'white':
            return WhiteStyles;
        case 'black':
            return BlackStyles;
        default:
            break;
    }
}}

  ${({ hoverColour }) => {
    switch (hoverColour) {
        case 'primary':
            return css `
          ${HoverEffect} {
            color: ${brand.primary.alt} !important;
          }

          ${Inner} {
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
          }

          &:hover {
            ${Inner} {
              background-color: ${brand.primary.alt} !important;
              border-color: ${brand.primary.alt} !important;
            }
          }
        `;
        default:
            break;
    }
}}

  ${({ hideTextMobile }) => {
    if (hideTextMobile) {
        return hideText;
    }
}}
`;
export const ButtonStyles = {
    Button,
    HoverEffect,
    Icon,
    Inner,
    Text,
};
