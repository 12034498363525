import brand from '@helpers/brand';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import SiteWideStyles from '@stories/Components/Containers/SiteWide/SiteWide.styles';
import styled from 'styled-components';
const Wrapper = styled.div `
  position: fixed;
  bottom: 30px;
  width: 100%;
  z-index: 100;
  pointer-events: none;

  ${SiteWideStyles.Container} {
    display: flex;
    justify-content: flex-end;
  }

  ${ButtonStyles.Button} {
    grid-column: -1;
    pointer-events: all;

    &:focus,
    &:active {
      color: ${brand.white};
    }
  }
`;
export const EnquireNowStyles = {
    Wrapper,
};
