import { HydrateOption } from '@core/enums';
import ReactPropsHelper from '@helpers/ReactPropsHelper';
import { isSSR } from '@helpers/ssr';
import { createElement } from 'react';
import { hydrate } from 'react-dom';
import 'regenerator-runtime/runtime';
export default function (component, componentName, options) {
    if (isSSR() || options.hydrate === HydrateOption.Never) {
        return component;
    }
    const configs = ReactPropsHelper.props.filter((x) => x.name.toLowerCase() === `components.${componentName.toLowerCase()}`);
    if (!configs || configs.length === 0) {
        return component;
    }
    for (let index = 0; index < configs.length; index++) {
        const config = configs[index];
        const container = document.querySelector(`#${config.id}:not(.hydrated)`);
        if (!container) {
            continue;
        }
        container.classList.add('hydrated');
        // Not supported in Safari iOS
        if (window.requestIdleCallback) {
            window.requestIdleCallback(() => {
                const element = createElement(component, config.props);
                // const t0 = performance.now();
                hydrate(element, container);
                // const t1 = performance.now();
                // console.log(`${componentName} hydrate took ${t1 - t0} milliseconds.`);
            });
        }
        else {
            hydrate(createElement(component, config.props), container);
        }
    }
    return component;
}
