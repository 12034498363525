export var MediaType;
(function (MediaType) {
    MediaType[MediaType["Image"] = 0] = "Image";
    MediaType[MediaType["YouTube"] = 1] = "YouTube";
})(MediaType || (MediaType = {}));
export var HireViewType;
(function (HireViewType) {
    HireViewType[HireViewType["ReadOnly"] = 0] = "ReadOnly";
    HireViewType[HireViewType["Editable"] = 1] = "Editable";
})(HireViewType || (HireViewType = {}));
export var OrderStepProgress;
(function (OrderStepProgress) {
    OrderStepProgress[OrderStepProgress["Inactive"] = 0] = "Inactive";
    OrderStepProgress[OrderStepProgress["Complete"] = 1] = "Complete";
    OrderStepProgress[OrderStepProgress["Current"] = 2] = "Current";
})(OrderStepProgress || (OrderStepProgress = {}));
export var HydrateOption;
(function (HydrateOption) {
    HydrateOption[HydrateOption["Always"] = 0] = "Always";
    HydrateOption[HydrateOption["InView"] = 1] = "InView";
    HydrateOption[HydrateOption["Never"] = 2] = "Never";
})(HydrateOption || (HydrateOption = {}));
