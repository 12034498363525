import { isSSR } from '@helpers/ssr';
import * as React from 'react';
import { useLocation } from 'react-use';
const EnquireNowModalLazy = React.lazy(() => import('./EnquireNowModal.lazy'));
const EnquireNowModal = (props) => {
    const location = useLocation();
    const isActive = location.hash === '#enquire';
    // Cannot use portals on server.
    if (isSSR() || !isActive) {
        return null;
    }
    return (React.createElement(React.Suspense, { fallback: null },
        React.createElement(EnquireNowModalLazy, { ...props })));
};
export default EnquireNowModal;
