import { Device, until } from '@helpers/media';
import styled, { css } from 'styled-components';
export const SITE_WIDTH = 1638;
export const SITE_PADDING_MOBILE = 10;
export const SITE_PADDING = 20;
const containerStyles = css `
  margin-left: auto;
  margin-right: auto;
  max-width: calc(${SITE_WIDTH}px + (${SITE_PADDING}px * 2));
  padding-right: ${SITE_PADDING}px;
  padding-left: ${SITE_PADDING}px;

  @media ${until(Device.TabletLarge)} {
    max-width: none;
    padding-right: ${SITE_PADDING_MOBILE}px;
    padding-left: ${SITE_PADDING_MOBILE}px;
  }
`;
const Container = styled.div `
  ${containerStyles}
`;
export default {
    Container,
    containerStyles,
};
