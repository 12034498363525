import { css } from 'styled-components';
export default {
    navHeight: 107,
    navHeightTablet: 80,
    navHeightMobile: 59,
};
export const ButtonReset = css `
  border: none;
  outline: none;
  padding: 0px;
  background: none;
  border-radius: 0px;
`;
