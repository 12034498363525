import { css } from 'styled-components';
export const fonts = {
    DaxPro: {
        Bold: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
    `,
        BoldItalic: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: italic;
      font-weight: 700;
    `,
        Medium: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: normal;
      font-weight: 600;
    `,
        SlightlyLessThanMedium: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
    `,
        Regular: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
    `,
        RegularItalic: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: italic;
      font-weight: 400;
    `,
        Light: css `
      font-family: 'ff-dax-pro', Arial, sans-serif;
      font-style: normal;
      font-weight: 200;
    `,
    },
};
